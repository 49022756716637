import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { MDXProvider } from "@mdx-js/react"

import SvgWithCaption from "./svg-with-caption"
import { rhythm, scale } from "../utils/typography"

const shortcodes = { SvgWithCaption }

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          to="/"
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          rel="home"
        >
          <Image
            fixed={data.logo.childImageSharp.fixed}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
            }}
            imgStyle={{}}
          />
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          to="/"
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          rel="home"
        >
          <Image
            fixed={data.logo.childImageSharp.fixed}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
            }}
            imgStyle={{}}
          />
        </Link>
      </h3>
    )
  }
  return (
    <MDXProvider components={shortcodes}>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(26),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <header style={{ flexShrink: 0 }}>{header}</header>
        <main style={{ flexGrow: 1 }}>{children}</main>
        <footer style={{ flexShrink: 0, textAlign: "center" }}>
          <a href="https://statechannels.org/" target="_blank">
            Website
          </a>{" "}
          · <a href="/">Blog</a> ·{" "}
          <a href="https://twitter.com/statechannels" target="_blank">
            Twitter
          </a>{" "}
          ·{" "}
          <a href="https://github.com/statechannels" target="_blank">
            Github
          </a>
          <br />
          Copyright © 2020{" "}
          <a href="https://statechannels.org" target="_blank">
            State Channels
          </a>{" "}
          contributors.
        </footer>
      </div>
    </MDXProvider>
  )
}

export default Layout
