import React from "react"
import styled from "styled-components"

const Box = styled.div({
  paddingBottom: "1em",
})
const Caption = styled.div({
  textAlign: "center",
  fontSize: "80%",
})

const SvgWithCaption = ({ svg, caption }) => {
  return (
    <Box>
      {svg}
      <Caption>{caption}</Caption>
    </Box>
  )
}

export default SvgWithCaption
